import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import Link from '../components/link'
import URLS from '../urls'

import logoUndefined from '../images/logo-undefined.png'

const Footer = () => (
  <footer>
    <div className="content">
      <div className="created-by">
        <h3>Created by</h3>
        <Link href={URLS.UNDEFINED_LABS.HOME}>
          <img src={logoUndefined} alt="Undefined Labs" />
        </Link>
      </div>

      <nav className="links menu">
        <h3>Menu</h3>
        <ul>
          <li>
            <Link href={URLS.SCOPE.DOCS}>Docs</Link>
          </li>
          <li>
            <Link href={URLS.SCOPE.BLOG}>Blog</Link>
          </li>
          <li>
            <Link href={URLS.UNDEFINED_LABS.HOME}>Company</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms</Link>
          </li>
        </ul>
      </nav>

      <div className="links social">
        <h3>Follow us</h3>
        <ul>
          <li>
            <Link href={URLS.SCOPE.TWITTER} title="Twitter">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </Link>
          </li>
          <li>
            <Link href={URLS.UNDEFINED_LABS.LINKEDIN} title="LinkedIn">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </Link>
          </li>
        </ul>
      </div>

      <div className="links contact">
        <h3>Contact us</h3>
        <Link href={`mailto:${URLS.SCOPE.EMAIL}`}>{URLS.SCOPE.EMAIL}</Link>
      </div>
    </div>
  </footer>
)

export default Footer
