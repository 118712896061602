import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ href, to, children, newTab = true, ...rest }) => {
  if (to) {
    return (
      <GatsbyLink to={to} {...rest}>
        {children}
      </GatsbyLink>
    )
  }

  const props = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  return (
    <a href={href} {...props} {...rest}>
      {children}
    </a>
  )
}

export default Link
