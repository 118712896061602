module.exports = {
  SCOPE: {
    APP: 'https://app.scope.dev',
    LOGIN: 'https://app.scope.dev/api/oauth/login/github',
    DEMO:
      'https://app.scope.dev/dashboard/f0a213f0-b550-4bb0-a651-c1d5b9eff041/',
    DOCS: 'https://docs.scope.dev/docs',
    BLOG: 'https://medium.com/scopedev',
    TWITTER: 'https://twitter.com/scope_dev',
    EMAIL: 'contact@scope.dev',
  },
  UNDEFINED_LABS: {
    HOME: 'https://undefinedlabs.com',
    LINKEDIN: 'https://www.linkedin.com/company/undefinedlabs/about',
    EMAIL: 'info@undefinedlabs.com',
  },
}
