import React from 'react'

import Link from './link'
import URLS from '../urls'

import logoScope from '../images/logo-scope.png'
import ciVisibilityIcon from '../images/features/ci-visibility-icon.svg'
import flakyTestManagementIcon from '../images/features/flaky-test-management-icon.svg'
import intelligentTestRunnerIcon from '../images/features/intelligent-test-runner-icon.svg'

const FeaturesMenu = () => (
  <div className="features-menu">
    <ul className="content">
      <li>
        <Link to="/ci-visibility">
          <img src={ciVisibilityIcon} alt="" />
          <div>
            <h3>CI Visibility</h3>
            <p className="note">Production-level visibility in CI.</p>
          </div>
        </Link>
      </li>
      <li>
        <Link to="/flaky-test-management">
          <img src={flakyTestManagementIcon} alt="" />
          <div>
            <h3>Flaky Test Management</h3>
            <p className="note">Identify, triage, and fix test flakiness.</p>
          </div>
        </Link>
      </li>
      <li>
        <Link to="/intelligent-test-runner">
          <img src={intelligentTestRunnerIcon} alt="" />
          <div>
            <h3>Intelligent Test Runner</h3>
            <p className="note">Reduce testing times by up to 98%.</p>
          </div>
        </Link>
      </li>
    </ul>
  </div>
)

const Header = ({ decoration }) => (
  <>
    <header>
      <div>
        <h1>
          <Link to="/">
            <img src={logoScope} alt="Scope" />
          </Link>
        </h1>

        <nav>
          <ul>
            <li className="nav-item features">
              <span>Features</span>
              <FeaturesMenu />
            </li>
            <li className="nav-item">
              <Link href={URLS.SCOPE.BLOG}>Blog</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>

    {decoration && (
      <svg
        className="header-decoration"
        viewBox="0 0 1440 110"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          className="secondary"
          d="m-281.54-133.21s518.41 310.72 779.41 221.72 521.13-92.516 712.13-52.516 644.87 249.52 444.87 116.52 18-180 18-180l-461.61-950.42-416 4s-991.41 16.119-1126.7 21.152c-88.475 3.292 49.89 819.55 49.89 819.55z"
          opacity=".2"
        />
        <path
          d="m285.07 60.907c-322.93 66.907-535-58-535-58l52.444-959.32s433.18 23.316 815.18 21.316 664.3-18.789 703.77 16 215.54 953.09 215.54 953.09-274-69-605 12c-252.25 61.729-324-52-646.93 14.907z"
          fill="url(#gradient)"
        />
        <defs>
          <linearGradient id="gradient" x1="0%" y1="60%" x2="0%" y2="100%">
            <stop offset="83%" stopOpacity="1" />
            <stop offset="100%" stopOpacity="0.85" />
          </linearGradient>
        </defs>
      </svg>
    )}
  </>
)

export default Header
