import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import socialMediaImage from '../images/social-media.jpg'

const siteMetadataQuery = graphql`
  query SiteMetadataQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
        siteName
        imageAlt
        keywords
      }
    }
  }
`

const SEO = () => {
  const siteMetadata = useStaticQuery(siteMetadataQuery)
  const {
    title,
    description,
    author,
    url,
    siteName,
    imageAlt,
    keywords,
  } = siteMetadata.site.siteMetadata

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          name: 'og:image',
          content: `${url}${socialMediaImage}`,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: siteName,
        },
        {
          name: 'twitter:creator',
          content: author,
        },
        {
          property: 'twitter:image:alt',
          content: imageAlt,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ].concat(
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : []
      )}
    />
  )
}

export default SEO
